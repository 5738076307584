import React from "react";
import "./Footer.css";
import Wave from "../../img/wave.png";
import Insta from "@iconscout/react-unicons/icons/uil-instagram";
import Facebook from "@iconscout/react-unicons/icons/uil-facebook";
import Gitub from "@iconscout/react-unicons/icons/uil-github";

const Footer = () => {
  return (
    <div className="footer">
      <img src={Wave} alt="" style={{ width: "100%" }} />
      <div className="f-content">
        <span>christianod97@gmail.com</span>
        <span>+261336134752</span>
        <div className="f-icons">
          <Insta color="white" size={"3rem"} onClick={ () => window.open('https://instagram.com/christianoh.dc')}/>
          <Facebook color="white" size={"3rem"} onClick={ () => window.open('https://facebook.com/27.dchna')}/>
          <Gitub color="white" size={"3rem"} onClick={ () => window.open('https://github.com/ChristianoDc7')}/>
        </div>
      </div>
    </div>
  );
};

export default Footer;
