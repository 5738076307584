import React, { useContext } from 'react';
import './Works.css';
import Clicar from '../../img/clicar.jpeg';
import Oppy from '../../img/opportunity.jpeg';
import Corellia from '../../img/corellia.svg';
import Airtel from '../../img/airtel.jpeg';
import ISA from '../../img/isa.jpeg';
import { themeContext } from '../../Context';
import { motion } from 'framer-motion';
import { Link } from 'react-scroll';
const Works = () => {
    // context
    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;

    // transition
    return (
        <div className="works" id="works">
            {/* left side */}
            <div className="w-left">
                <div className="awesome">
                    {/* dark Mode */}
                    <span style={{ color: darkMode ? 'white' : '' }}>Freelance or Fulltime</span>
                    <span>Always motivated</span>
                    <span>
                        <br />
                        Autonomous, insightful and determined for my work
                        <br />
                        These companies have already been satisfied with my work.
                        <br />
                        Don't hesitate to contact me if you want more information
                    </span>
                    <Link to="contact" smooth={true} spy={true}>
                        <button className="button s-button">Get in touch</button>
                    </Link>
                    <div className="blur s-blur1" style={{ background: '#ABF1FF94' }}></div>
                </div>

                {/* right side */}
            </div>
            <div className="w-right">
                <motion.div
                    initial={{ rotate: 45 }}
                    whileInView={{ rotate: 0 }}
                    viewport={{ margin: '-40px' }}
                    transition={{ duration: 3.5, type: 'spring' }}
                    className="w-mainCircle"
                >
                    <div className="w-secCircle">
                        <img src={Clicar} alt="" />
                    </div>
                    <div className="w-secCircle">
                        <img src={Corellia} alt="" />
                    </div>
                    <div className="w-secCircle">
                        <img src={Oppy} alt="" />
                    </div>
                    <div className="w-secCircle">
                        <img src={ISA} alt="" />
                    </div>
                    <div className="w-secCircle">
                        <img src={Airtel} alt="" />
                    </div>
                </motion.div>
                {/* background Circles */}
                <div className="w-backCircle blueCircle"></div>
                <div className="w-backCircle yellowCircle"></div>
            </div>
        </div>
    );
};

export default Works;
