import React, { useContext } from 'react';
import './Services.css';
import Card from '../Card/Card';
import HeartEmoji from '../../img/heartemoji.png';
import Glasses from '../../img/glasses.png';
import Humble from '../../img/humble.png';
import { themeContext } from '../../Context';
import { motion } from 'framer-motion';
import Resume from './resume.pdf';

const Services = () => {
    // context
    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;

    // transition
    const transition = {
        duration: 1,
        type: 'spring',
    };

    return (
        <div className="services" id="services">
            {/* left side */}
            <div className="awesome">
                {/* dark mode */}
                <span style={{ color: darkMode ? 'white' : '' }}>My awesome </span>
                <span>Services</span>
                <span>
                    Crafting, Developing, and Maintaining Powerful Web Applications for Your Success.
                    <br />
                    Your digital project partner
                </span>
                <a href={Resume} download>
                    <button className="button s-button">Download CV</button>
                </a>
                <div className="blur s-blur1" style={{ background: '#ABF1FF94' }}></div>
            </div>
            {/* right */}
            <div className="cards">
                {/* first card */}
                <motion.div initial={{ left: '25rem' }} whileInView={{ left: '14rem' }} transition={transition}>
                    <Card
                        emoji={HeartEmoji}
                        heading={'Frontend Specialist'}
                        detail={'React , Angular, Typescript, SASS, TailwindCSS'}
                    />
                </motion.div>
                {/* second card */}
                <motion.div
                    initial={{ left: '-11rem', top: '12rem' }}
                    whileInView={{ left: '-4rem' }}
                    transition={transition}
                >
                    <Card
                        emoji={Glasses}
                        heading={'Backend Developer'}
                        detail={'Nodejs, Express, PHP, NestJS , .Net Core , MongoDB, MySQL, PostgreSQL'}
                    />
                </motion.div>
                {/* 3rd */}
                <motion.div
                    initial={{ top: '19rem', left: '25rem' }}
                    whileInView={{ left: '12rem' }}
                    transition={transition}
                >
                    <Card
                        emoji={Humble}
                        heading={'Devops Engineer'}
                        detail={'Docker, Jenkins, Gitlab CI/CD, Github Actions, AWS, Azure'}
                        color="rgba(252, 166, 31, 0.45)"
                    />
                </motion.div>
                <div className="blur s-blur2" style={{ background: 'var(--purple)' }}></div>
            </div>
        </div>
    );
};

export default Services;
