import React, { useContext } from 'react';
import './Portfolio.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import frontend from '../../img/FrontendAwards.png';
import Agri from '../../img/Agri.png';
import Resto from '../../img/RestoApp.png';
import ReactManagementAuth from '../../img/ReactManagementAuth.png';
import ecommerce from '../../img/e-commerce.png';

import { themeContext } from '../../Context';
const Portfolio = () => {
    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;
    return (
        <div className="portfolio" id="portfolio">
            {/* heading */}
            <span style={{ color: darkMode ? 'white' : '' }}>Some personal Projects</span>

            {/* slider */}
            <Swiper spaceBetween={30} slidesPerView={3} grabCursor={true} className="portfolio-slider">
                <SwiperSlide>
                    <img src={frontend} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={Agri} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={Resto} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={ReactManagementAuth} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={ecommerce} alt="" />
                </SwiperSlide>
            </Swiper>
        </div>
    );
};

export default Portfolio;
