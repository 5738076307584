import React, { useContext, useRef, useState } from 'react';
import './Contact.css';
import emailjs from '@emailjs/browser';
import { themeContext } from '../../Context';
const Contact = () => {
    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;
    const form = useRef();
    const [done, setDone] = useState(false);
    const [loading, setLoading] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        setLoading(true);
        emailjs.sendForm('service_k3l3fwy', 'template_sid5pfu', form.current, 'csMAy6pTbtLmhMwUg').then(
            (result) => {
                if (result.text.toLowerCase() == 'ok') {
                    console.log('Message send successfully');
                }
                setLoading(false);
                setDone(true);
                form.current.reset();
                e.target.reset();
            },
            (error) => {
                setLoading(false);
                console.log(error.text);
            }
        );
    };

    return (
        <div className="contact-form" id="contact">
            {/* left side copy and paste from work section */}
            <div className="w-left">
                <div className="awesome">
                    {/* darkMode */}
                    <span style={{ color: darkMode ? 'white' : '' }}>Get in Touch</span>
                    <span>Contact me</span>
                    <div className="blur s-blur1" style={{ background: '#ABF1FF94' }}></div>
                </div>
            </div>
            {/* right side form */}
            <div className="c-right">
                <form ref={form} onSubmit={sendEmail}>
                    <input type="text" name="user_name" className="user" placeholder="Name" />
                    <input type="email" name="user_email" className="user" placeholder="Email" />
                    <textarea name="message" className="user" placeholder="Message" />
                    <input
                        type="submit"
                        disabled={loading}
                        value={loading ? 'En cours...' : 'Send'}
                        className="button"
                    />
                    <span>{done && 'Thanks for Contacting me '}</span>
                    <div className="blur c-blur1" style={{ background: 'var(--purple)' }}></div>
                </form>
            </div>
        </div>
    );
};

export default Contact;
